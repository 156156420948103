import { useEffect, useState } from "react";
import axios from "axios";
import { Gizmo } from "flowy-3-core";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { latLngBounds, icon } from "leaflet";
import config from "../../../../config";

type MapProps = {
  gizmo: Gizmo;
  // columns: IIndexColumn[];
  // rows: any[];
  // pagination: any;
};

const Map = ({ gizmo }: MapProps) => {
  const [locations, setLocations] = useState<any[]>([]);

  useEffect(() => {
    const params = gizmo.getParams();
    const binder = gizmo.getBinder();

    if (params?.fid && binder) {
      binder
        .getLocations({ params })
        .then((locations: any) => {
          setLocations(locations);
        })
        .catch((error: any) => {
          console.log("Map.error: ", error);
        });
    }
  }, []);

  const ChangeView = () => {
    const map = useMap();
    // const markerBounds: any = locations.map((location) => [location.lat, location.lng]);
    // console.log('ChangeView.markerBounds: ', markerBounds);
    let markerBounds = latLngBounds([]);
    locations.forEach((location) => {
      markerBounds.extend([location.lat, location.lng]);
    });
    // console.log('ChangeView.markerBounds: ', markerBounds, ', keys: ', Object.keys(markerBounds));
    if (Object.keys(markerBounds).length > 0) {
      map.fitBounds(markerBounds);
    }

    return null;
  };

  // Function to create an icon
  // const createIcon = (color: string): any => {
  // 	console.log('createIcon.color: ', color);
  //   return icon({
  //     iconUrl: `http://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${color}`,
  //     iconSize: [25, 41],
  //     iconAnchor: [12, 41],
  //   });
  // }

  return (
    <div>
      <h1>Map</h1>
      <MapContainer
        // center={[51.505, -0.09]}
        center={{ lat: 19.444852, lng: -99.202797 }}
        // zoom={13}
        zoom={18}
        style={{ height: "100vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index: number) => (
          <Marker
            key={location.id}
            position={[location.lat, location.lng]}
            // icon={createIcon(index % 2 === 0 ? "red" : "blue")}
          >
            <Popup>
              {location.name} {/* Replace with the actual property names */}
            </Popup>
          </Marker>
        ))}
        <ChangeView />
      </MapContainer>
    </div>
  );
};

export default Map;
