import { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./RateField.styles";
import { GizmoWrapper } from "../../utils";

type RateFieldProps = {
  gizmo: Gizmo;
};

const RateField: FC<RateFieldProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (binder) {
      const initValue = binder.getValue();
      if (initValue !== undefined) {
        setValue(initValue);
      }

      binder.value.subscribe((v: number) => {
        setValue(v);
      });
    }
  }, [binder]);

  const handleRateChange = (value: number) => {
    binder?.setValue(value);
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      <S.RateField
        aria-label={`input-${config.fid}`}
        onChange={handleRateChange}
        value={value}
        allowClear
        disabled={!features.editable}
      />
    </GizmoWrapper>
  );
};

export default RateField;
