import { Input } from "antd";
import styled from "styled-components";

const TextField = styled(Input)`
  width: 100%;
`;

const TextArea = styled(Input.TextArea)`
  width: 100%;
`;

export { TextField, TextArea };
