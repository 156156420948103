import { FC, useEffect, useState } from "react";
import { Gizmo, IGizmo } from "flowy-3-core";
import * as S from "./Custom.styles";

type CustomProps = {
  gizmo: Gizmo;
};

const Custom: FC<CustomProps> = ({ gizmo }) => {
  const config: IGizmo = gizmo.getConfig();
  const [customGizmo, setCustomGizmo] = useState<any | undefined>();

  useEffect(() => {
    if (
      config.ops?.custom?.component &&
      typeof config.ops.custom.component === "function"
    ) {
      const AnotherComponent = config.ops.custom.component;
      setCustomGizmo(<AnotherComponent gizmo={gizmo} />);
    } else if (
      config.ops?.custom?.component &&
      typeof config.ops.custom.component === "string"
    ) {
      import(`../../../../client/custom/${config.ops.custom.component}`)
        .then((module) => {
          const AnotherComponent = module.default;
          setCustomGizmo(<AnotherComponent gizmo={gizmo} />);
        })
        .catch((e) => {
          setCustomGizmo(null);
        });
    }
  }, []);

  return (
    <S.Custom
      id={`custom-${gizmo.getConfig().fid}`}
      columns={gizmo.getColumns()}
    >
      {customGizmo !== undefined && !null && customGizmo}
      {customGizmo === null && (
        <h4>Gizmo custom "{config.ops?.custom?.component}" No Existe</h4>
      )}
    </S.Custom>
  );
};

export default Custom;
