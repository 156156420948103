import { FC, useState, useEffect } from "react";
import { Modal } from "antd";
import { Gizmo, IGizmo } from "flowy-3-core";

type PrivacyModalProps = {
  open: boolean;
  gizmo: Gizmo;
  setOpen: (open: boolean) => void;
};

const PrivacyModal: FC<PrivacyModalProps> = ({ open, gizmo, setOpen }) => {
  const [title, setTitle] = useState<string>("Aviso de Privacidad");
  const [policy, setPolicy] = useState<string>("");

  useEffect(() => {
    const binder = gizmo.getBinder();

    if (binder) {
      const index = binder.getIndex();

      if (index?.rootGizmoConfigs && index?.rootGizmoConfigs.length > 0) {
        const rootGizmoConfig: IGizmo = index.rootGizmoConfigs[0];

        if (rootGizmoConfig.ops?.form?.privacyPolicy) {
          const { title, policy } = rootGizmoConfig.ops.form.privacyPolicy;

          if (title) {
            setTitle(title);
          }
          if (policy) {
            setPolicy(policy);
          }
        }
      }
    }
  }, []);

  const handleOk = async () => {
    const binder = gizmo.getBinder();
    await binder.dispatchOperation({
      operation: "navigation.link",
      params: {
        path: `/rg?rgfid=${gizmo.getParams().fid}`,
      },
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      // style={{ margin: 11 }}
    >
      {policy}
    </Modal>
  );
};

export default PrivacyModal;
