import { FC, useEffect, useState } from "react";
import { Gizmo, IIndexColumn } from "flowy-3-core";
import { Space, Button, Checkbox } from "antd";
import * as S from "./Index.styles";

type StatusProps = {
  selectedKeys: string[];
  setSelectedKeys: Function;
  visible: boolean;
  confirm: Function;
  close: Function;
  column: IIndexColumn;
  gizmo: Gizmo;
  setLoading: Function;
  setColumns: Function;
  setRows: Function;
  setPagination: Function;
};

const ColumnHeaderStatus: FC<StatusProps> = ({
  gizmo,
  selectedKeys,
  setSelectedKeys,
  setLoading,
  close,
  setRows,
  setPagination,
}) => {
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<any[]>([]);

  useEffect(() => {
    const binder = gizmo.getBinder();

    const statuses = binder?.getStatuses();
    const statusFilter = binder
      ?.getFilters()
      ?.find((f: any) => f.type === "status");

    if (statusFilter?.keys) {
      setSelectedKeys(statusFilter.keys);
    }
    if (statuses && Array.isArray(statuses)) {
      setStatuses(statuses);
    }
  }, []);

  const handleStatusCheckboxChange = (status: any) => {
    if (selectedKeys.includes(status.f_id)) {
      setSelectedKeys(selectedKeys.filter((k: string) => k !== status.f_id));
    } else {
      setSelectedKeys([...selectedKeys, status.f_id]);
    }
  };

  const handleAcceptButtonClick = async (selectedKeys: string[]) => {
    setLoading(true);
    setButtonsDisabled(true);
    const binder = gizmo.getBinder();
    if (binder) {
      await binder.statusFilter({ statuses: selectedKeys });

      const index = binder.getIndex();
      if (index) {
        setRows(index.rows);
        setPagination(index.pagination);
      }
    }
    setButtonsDisabled(false);
    close();
    setLoading(false);
  };

  const handleResetButtonClick = async () => {
    setSelectedKeys([]);
    await handleAcceptButtonClick([]);
  };

  return (
    <Space direction="vertical" style={{ padding: "11px" }}>
      {statuses.map((status: any) => (
        <Checkbox
          key={status.f_id}
          checked={selectedKeys.includes(status.f_id)}
          onChange={() => handleStatusCheckboxChange(status)}
        >
          <S.Circle color={status.color}>◉ </S.Circle>
          {status.title}
        </Checkbox>
      ))}
      <Space>
        <Button
          type="link"
          size="small"
          disabled={buttonsDisabled}
          onClick={handleResetButtonClick}
        >
          Reiniciar
        </Button>
        <Button
          type="primary"
          size="small"
          disabled={buttonsDisabled}
          onClick={async () => {
            await handleAcceptButtonClick(selectedKeys);
          }}
        >
          Aceptar
        </Button>
      </Space>
    </Space>
  );
};

export default ColumnHeaderStatus;
