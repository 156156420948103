import React, { FC, useState, useEffect, useImperativeHandle } from "react";
import { Gizmo, IGizmoFeatures, ILocationData } from "flowy-3-core";
import { Spin, message } from "antd";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  Circle,
} from "react-leaflet";
import { latLng } from "leaflet";

type LocationMapProps = {
  locations: ILocationData[];
  gizmo: Gizmo;
  features: IGizmoFeatures;
};

/**
 * OpenStreetMap locations renderer
 */
const OSMLocations = React.forwardRef(
  ({ features, locations }: LocationMapProps, ref: any) => {
    const [loading, setLoading] = useState(true);
    // In this variable we're going to set the permission state in order to display a message
    // accordingly
    const [permission, setPermission] = useState<string | undefined>();
    const [userLocation, setUserLocation] = useState<
      google.maps.LatLngLiteral | undefined
    >(undefined);
    const [centerLocation, setCenterLocation] = useState<
      google.maps.LatLngLiteral | undefined
    >(undefined);
    const [isLimited, setIsLimited] = useState(false);
    const [limitRadius, setLimitRadius] = useState(100);
    // This locations are the ones currently selected by the user, when the user accepts they
    // will be assigned to the gizmo
    const [selectedLocations, setSelectedLocations] = useState<ILocationData[]>(
      []
    );
    const [messageApi, contextHolder] = message.useMessage();

    useImperativeHandle(ref, () => ({
      /**
       * When the modal ok function is called, this function is called
       * @returns The locations selected by the user
       */
      getLocations() {
        return selectedLocations;
      },
      /**
       * When the modal cancel function is called, this function is called
       */
      onCancel() {
        setSelectedLocations(locations);
      },
    }));

    useEffect(() => {
      // Right now we're only supporting saving one location
      if (locations.length === 1) {
        setCenterLocation({
          lat: locations[0].latitude,
          lng: locations[0].longitude,
        });
        setSelectedLocations(locations);
      }

      if (navigator.geolocation) {
        // In here we can check if the user has granted permission to access the location
        navigator.permissions
          .query({ name: "geolocation" })
          .then((result: PermissionStatus) => {
            if (result.state === "denied" || result.state === "prompt") {
              setLoading(false);
            }

            setPermission(result.state);
            result.addEventListener("change", () => {
              if (result.state === "granted") {
                setLoading(true);
              }
              setPermission(result.state);
            });
          });

        // After a couple of seconds we're going the get the current user's position
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });

          if (locations.length === 0) {
            setCenterLocation({ lat: latitude, lng: longitude });
            setSelectedLocations([{ latitude, longitude }]);
          }
          setLoading(false);
        });
      }

      if (features.locationField?.isLimited === true) {
        setIsLimited(true);
        setLimitRadius(features.locationField.limitRadius || 100);
      }
    }, [ref]);

    useEffect(() => {
      setSelectedLocations(locations);
    }, [locations]);

    const Markers = () => {
      return (
        <>
          {selectedLocations.length > 0 &&
            selectedLocations.map((loc: ILocationData, index) => {
              return (
                <Marker
                  key={index}
                  position={{ lat: loc.latitude, lng: loc.longitude }}
                />
              );
            })}
        </>
      );
    };

    const ComponentForEvents = () => {
      // This component is used to handle the click event on the map
      useMapEvents({
        click(e) {
          if (e.latlng) {
            const { lat, lng } = e.latlng;

            if (isLimited && userLocation && limitRadius) {
              const distance = latLng(
                userLocation.lat,
                userLocation.lng
              ).distanceTo(latLng(lat, lng));

              if (distance > limitRadius) {
                messageApi.open({
                  type: "warning",
                  content:
                    "El punto seleccionado está fuera del rango permitido.",
                });
                return;
              }
            }

            setSelectedLocations([{ latitude: lat, longitude: lng }]);
          }
        },
      });

      return null;
    };

    return (
      <>
        {permission === "denied" && !userLocation && (
          <h3>No se tiene permiso para accesar a la ubicación.</h3>
        )}
        {permission === "prompt" && !userLocation && (
          <h3>Favor de autorizar accesar a la ubicación.</h3>
        )}
        {loading && <Spin size="large" />}
        {userLocation && (
          <MapContainer
            center={centerLocation}
            zoom={18}
            style={{ width: "100%", height: "65vh" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {contextHolder}

            <Markers />
            {isLimited && limitRadius && (
              <Circle
                center={userLocation}
                radius={limitRadius}
                pathOptions={{ color: "#00AA00", fillColor: "#00AA00" }}
              />
            )}

            <ComponentForEvents />
          </MapContainer>
        )}
      </>
    );
  }
);

export default OSMLocations;
